import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import { SEO } from "components"

import Intro from "./_intro"
import Collage from "./_collage"
import Form from "./_form"
import CorporateInfo from "./_corporateInfo"

function ContactPage() {
  const { page } = useStaticQuery(PAGE_QUERY)

  return (
    <>
      <SEO
        title={page.data.seo_title && page.data.seo_title.text}
        description={page.data.seo_text && page.data.seo_text.text}
        imageOpenGraph={page.data.seo_image && page.data.seo_image.url}
        imageTwitter={page.data.seo_image && page.data.seo_image.url}
        imageAlt={page.data.seo_image && page.data.seo_image.alt}
      />
      <Intro
        data={{
          heading: page.data.subtitle && page.data.subtitle.html,
          description: page.data.subtitle_text && page.data.subtitle_text.html,
          address: {
            heading:
              page.data.address_heading && page.data.address_heading.text,
            addressStreet: page.data.address_building_number_and_street_name,
            addressSuite: page.data.address_suite_number,
            addressCity: page.data.address_city,
            addressProvince: page.data.address_province,
            addressPostal: page.data.address_postal,
            mapLink: page.data.map_link,
            phoneNumber: page.data.phone_number,
            faxNumber: page.data.fax_number,
            emergencyNumber: page.data.emergency_number,
            contactLinks: page.data.contact_links,
          },
          map: {
            map: page.data.map,
            mapLink: page.data.map_link,
          },
        }}
      />
      <Collage
        data={{
          primaryImage: page.data.office_interior,
          secondaryImage: page.data.office_exterior,
          bottomBackground: "Gray",
        }}
      />
      <Form
        data={{
          heading: page.data.form_heading && page.data.form_heading.text,
        }}
      />
      <CorporateInfo
        data={{
          heading:
            page.data.contacts_heading && page.data.contacts_heading.text,
          contacts: page.data.body,
        }}
      />
    </>
  )
}

const PAGE_QUERY = graphql`
  {
    page: prismicContact {
      data {
        seo_title {
          text
        }
        seo_text {
          text
        }
        seo_image {
          url
          alt
        }
        subtitle {
          html
        }
        subtitle_text {
          html
        }
        address_building_number_and_street_name
        address_city
        address_heading {
          text
        }
        address_postal
        address_province
        address_suite_number
        emergency_number
        fax_number
        contact_links {
          label
          link {
            url
          }
        }
        form_heading {
          text
        }
        contacts_heading {
          text
        }
        body {
          ... on PrismicContactBodyContactCategory {
            primary {
              category_title {
                text
              }
            }
            items {
              role
              name
              address {
                text
                html
              }
              website {
                url
              }
              phone
              fax
              email
            }
          }
        }
        map {
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        office_exterior {
          fluid(maxWidth: 1600) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        office_interior {
          fluid(maxWidth: 1600) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        map_link {
          url
        }
        phone_number
      }
    }
  }
`

export default ContactPage
