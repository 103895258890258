import React from "react"
import css from "@styled-system/css"

import { Box, Flex, Heading, Text } from "components"
import { FormattedPhoneLink, FormattedLink } from "components/formattedLink"

const Address = ({ data }) => (
  <>
    {/* Heading Text */}
    {data.heading && (
      <Heading
        children={data.heading}
        size={600}
        mb={["layout.2", "layout.3"]}
      />
    )}
    {/* Inner Flex, holding 2 columns of data, aligned horizontally.*/}
    <Flex
      flexWrap="wrap"
      m={[-16, null, -32]}
      css={css({
        "> *": {
          width: ["100%", null, "50%"],
          p: [16, null, 32],
        },
      })}
    >
      <Box>
        {/* Head Office */}
        {/* Teine address. */}
        <Box as="address" css={{ fontStyle: "normal" }}>
          <a
            href={data.mapLink && data.mapLink.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.addressSuite && (
              <>
                <Text
                  as="span"
                  size={500}
                  lineHeight={[24 + "px", 32 + "px"]}
                  children={data.addressSuite}
                />
                <br />
              </>
            )}
            {data.addressStreet && (
              <>
                <Text
                  as="span"
                  size={500}
                  lineHeight={[24 + "px", 32 + "px"]}
                  children={data.addressStreet}
                />
                <br />
              </>
            )}
            {(data.addressPostal ||
              data.addressProvince ||
              data.addressCity) && (
              <Text
                as="span"
                size={500}
                lineHeight={[24 + "px", 32 + "px"]}
                children={
                  data.addressCity +
                  (data.addressProvince && ", " + data.addressProvince) +
                  (data.addressPostal && " " + data.addressPostal)
                }
              />
            )}
          </a>
        </Box>
      </Box>
      {/* Phone, Fax and Emergency. */}
      <Box>
        <Box as="ul" px={0} m={0} css={{ listStyle: "none" }}>
          {data.phoneNumber && (
            <FormattedPhoneLink label="Phone" number={data.phoneNumber} />
          )}
          {data.faxNumber && (
            <FormattedPhoneLink label="Fax" number={data.faxNumber} />
          )}
          {data.emergencyNumber && (
            <FormattedPhoneLink
              label="Emergency"
              number={data.emergencyNumber}
            />
          )}
          {data?.contactLinks?.map((item, i) => {
            if (!item?.label || !item?.link?.url) return null
            return <FormattedLink label={item.label} link={item.link} key={i} />
          })}
        </Box>
      </Box>
    </Flex>
  </>
)

export default Address
