import React from "react"

import { Box, FancyLink, Flex, Text } from "components"

export const FormattedPhoneLink = ({ label, number, telLink = true }) => {
  if (!label || !number) return null

  return (
    <Box as="li">
      <Flex>
        <Text
          as="h6"
          size={500}
          mr="spacing.2"
          lineHeight={[24 + "px", 32 + "px"]}
          color="alt"
        >
          {label}
        </Text>
        <Text size={500} lineHeight={[24 + "px", 32 + "px"]}>
          <FancyLink as="a" href={(telLink ? "tel:" : "") + number}>
            {number}
          </FancyLink>
        </Text>
      </Flex>
    </Box>
  )
}

export const FormattedLink = props => {
  if (!props?.label || !props?.link?.url) return null

  return (
    <Text size={500} lineHeight={[24 + "px", 32 + "px"]} as="li">
      <FancyLink as="a" href={props.link.url}>
        {props.label}
      </FancyLink>
    </Text>
  )
}

export const FormattedPhoneNumber = ({ label, number }) => (
  <Box as="li">
    <Flex>
      <Text
        as="h6"
        children={label}
        size={500}
        mr="spacing.2"
        lineHeight={[24 + "px", 32 + "px"]}
        color="alt"
      />
      <Text size={500} lineHeight={[24 + "px", 32 + "px"]}>
        <FancyLink children={number} as="a" href={"tel:" + number} />
      </Text>
    </Flex>
  </Box>
)
