import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"
import css from "@styled-system/css"
import { motion } from "framer-motion"

// Project components
import { Box, FancyLink, Flex, Sidebar, Text, Wrapper } from "components"
import { FormattedPhoneLink, FormattedLink } from "components/formattedLink"

const HEADER_HEIGHT = 48
const HEADER_HEIGHT_MOBILE = 32

const DURATION = 0.6
const DELAY = 1.2
const EASE_OUT_QUINT = [0.23, 1, 0.32, 1]

const HeaderLink = ({ link, label }) => (
  <Text
    size={400}
    lineHeight={[HEADER_HEIGHT_MOBILE + "px", HEADER_HEIGHT + "px"]}
    css={css({ "&:not(:last-of-type)": { mr: "layout.3" } })}
  >
    <FancyLink as={Link} to={link && link.url}>
      {label}
    </FancyLink>
  </Text>
)

const HeaderLinkMobile = ({ link, label, onClick }) => (
  <Text fontSize={[24, 24, 24, 24]} px="layout.3" mb="layout.1" opacity={0.75}>
    <Link
      to={link && link.url}
      onClick={onClick}
      css={css({
        "&:focus": {
          outline: 0,
        },
      })}
    >
      {label}
    </Link>
  </Text>
)

function Header({ path }) {
  const [showMenu, setShowMenu] = useState(false)
  const { navigation, contact, settings } = useStaticQuery(MENU_QUERY)

  return (
    <Box
      data-component-id="header"
      as="header"
      position="absolute"
      top={[32, 96]}
      left={0}
      zIndex={1}
      width="100%"
    >
      <motion.div
        variants={{
          initial: {
            opacity: 0,
          },
          enter: {
            opacity: 1,
            transition: {
              duration: DURATION,
              delay: path === "/" ? DELAY : 0,
              ease: EASE_OUT_QUINT,
            },
          },
          exit: {
            opacity: 0,
            transition: { duration: 0.3 },
          },
        }}
        initial="initial"
        animate="enter"
        exit="exit"
        key="header"
      >
        <Wrapper width="100%">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex flexWrap="wrap" alignItems="center">
              <Box>
                {/* Company logo */}
                <Box
                  as={Link}
                  to="/"
                  display="block"
                  position="relative"
                  width={[
                    settings.data.logo_dark.fluid.aspectRatio *
                      HEADER_HEIGHT_MOBILE +
                      "px",
                    settings.data.logo_dark.fluid.aspectRatio * HEADER_HEIGHT +
                      "px",
                  ]}
                  height={[HEADER_HEIGHT_MOBILE, HEADER_HEIGHT]}
                >
                  <Image
                    fluid={settings.data.logo_dark.fluid}
                    alt={settings.data.logo_dark.alt}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    objectFit="cover"
                    objectPosition="center"
                  />
                </Box>
              </Box>

              {settings.data.badge && settings.data.badge.fluid && (
                <Box
                  display="flex"
                  alignItems="center"
                  height={[HEADER_HEIGHT_MOBILE, HEADER_HEIGHT]}
                  pl={["layout.3", "layout.4"]}
                >
                  <Box
                    as={settings.data.badge_attribution.url ? Link : "div"}
                    to={
                      settings.data.badge_attribution.url
                        ? settings.data.badge_attribution.url
                        : undefined
                    }
                    target={
                      settings.data.badge_attribution.url ? "_blank" : undefined
                    }
                    rel={
                      settings.data.badge_attribution.url
                        ? "noopener noreferrer"
                        : undefined
                    }
                    position="relative"
                    display="block"
                    width={[
                      settings.data.badge.fluid.aspectRatio * 56 + "px",
                      settings.data.badge.fluid.aspectRatio * 80 + "px",
                    ]}
                    height={[56, 80]}
                  >
                    <Image
                      fluid={settings.data.badge.fluid}
                      alt={settings.data.badge.alt}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      objectFit="cover"
                      objectPosition="center"
                    />
                  </Box>
                </Box>
              )}
            </Flex>

            {/* Header navigation buttons */}
            <Flex display={["none", null, null, "flex"]}>
              {navigation.data.nav_links &&
                navigation.data.nav_links.map(({ label, link }, index) => (
                  <HeaderLink
                    label={label}
                    link={link}
                    key={"headerLink" + index}
                  />
                ))}
            </Flex>

            <Flex
              display={["flex", null, null, "none"]}
              onClick={() => {
                setShowMenu(true)
              }}
            >
              <Text
                size={500}
                lineHeight={[HEADER_HEIGHT_MOBILE + "px", HEADER_HEIGHT + "px"]}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                </svg>
              </Text>
            </Flex>
          </Flex>
        </Wrapper>
      </motion.div>
      {/* MOBILE MENU. */}
      <Sidebar showDialog={showMenu} setShowDialog={setShowMenu}>
        <Flex flexDirection="column" pt="96px">
          {/* Links. */}
          {navigation.data.nav_links &&
            navigation.data.nav_links.map(({ label, link }, index) => (
              <>
                <HeaderLinkMobile
                  label={label}
                  link={link}
                  onClick={() => {
                    setShowMenu(false)
                  }}
                  key={"headerLinkMobile" + index}
                />
              </>
            ))}

          <Box px="layout.3" py="layout.4" pt="48px">
            {/* Inner Flex, holding 2 columns of data, aligned horizontally. */}
            <Flex
              flexDirection="column"
              m={["-6px", "-6px", "-6px"]}
              css={css({
                "> *": {
                  width: ["100%", null, "50%"],
                  p: ["6px", null, "6px"],
                },
              })}
            >
              {/* Teine address. */}
              <Box
                as="address"
                css={{ fontStyle: "normal" }}
                width="100%"
                opacity={0.75}
              >
                <a
                  href={contact.data.map_link && contact.data.map_link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {contact.data.address_suite_number && (
                    <>
                      <Text
                        as="span"
                        size={500}
                        lineHeight={[24 + "px", 32 + "px"]}
                        children={contact.data.address_suite_number}
                      />
                      <br />
                    </>
                  )}
                  {contact.data.address_building_number_and_street_name && (
                    <>
                      <Text
                        as="span"
                        size={500}
                        lineHeight={[24 + "px", 32 + "px"]}
                        children={
                          contact.data.address_building_number_and_street_name
                        }
                      />
                      <br />
                    </>
                  )}
                  {(contact.data.address_postal ||
                    contact.data.address_province ||
                    contact.data.address_city) && (
                    <Text
                      as="span"
                      size={500}
                      lineHeight={[24 + "px", 32 + "px"]}
                      children={
                        contact.data.address_city +
                        (contact.data.address_province &&
                          ", " + contact.data.address_province) +
                        (contact.data.address_postal &&
                          " " + contact.data.address_postal)
                      }
                    />
                  )}
                </a>
              </Box>

              {/* Phone, Fax and Emergency. */}
              <Box as="ul" width="100%" m={0} css={{ listStyle: "none" }}>
                {contact.data.phone_number && (
                  <FormattedPhoneLink
                    label="Phone"
                    number={contact.data.phone_number}
                  />
                )}
                {contact.data.fax_number && (
                  <FormattedPhoneLink
                    label="Fax"
                    number={contact.data.fax_number}
                  />
                )}
                {contact.data.emergency_number && (
                  <FormattedPhoneLink
                    label="Emergency"
                    number={contact.data.emergency_number}
                  />
                )}
                {contact?.data?.contact_links?.map((item, i) => {
                  if (!item?.label || !item?.link?.url) return null
                  return (
                    <FormattedLink
                      label={item.label}
                      link={item.link}
                      key={i}
                    />
                  )
                })}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Sidebar>
    </Box>
  )
}

const MENU_QUERY = graphql`
  {
    navigation: prismicNavigation {
      data {
        nav_links {
          label
          link {
            url
          }
        }
      }
    }
    contact: prismicContact {
      data {
        address_suite_number
        address_building_number_and_street_name
        address_postal
        address_city
        address_province
        phone_number
        fax_number
        emergency_number
        contact_links {
          label
          link {
            url
          }
        }
        map_link {
          url
        }
      }
    }
    settings: prismicSettings {
      data {
        logo_light {
          fluid(maxHeight: 64) {
            ...GatsbyPrismicImageFluid
            aspectRatio
          }
          alt
        }
        logo_dark {
          fluid(maxHeight: 64) {
            ...GatsbyPrismicImageFluid
            aspectRatio
          }
          alt
        }
        badge {
          fluid(maxHeight: 192) {
            ...GatsbyPrismicImageFluid
            aspectRatio
          }
        }
        badge_attribution {
          url
        }
      }
    }
  }
`

export default Header
